import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';

import SectionContainer from '../SectionContainer';
import css from './SectionIframe.module.css';
import { getStoredLanguage } from '../../../../util/translations';

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const SectionIframe = props => {
  const {
    sectionId,
    className,
    rootClassName,
    appearance,
    options,
    pageId
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  // const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  // const hasBlocks = blocks?.length > 0;

  const weatherWidget = (island) => {
    const langCode = getStoredLanguage() ? getStoredLanguage() : 'en';
    const locations = {
      'pico': 'pico-island_portugal_3372803',
      'sao-jorge': 's%c3%a3o-jorge-island_portugal_3372606',
      'terceira': 'terceira-island_portugal_3372530',
      'flores': 'santa-cruz-das-flores_portugal_3372643',
      'corvo': 'corvo-island_portugal_3373167',
      'faial': 'faial-island_portugal_3373109',
      'graciosa': 'graciosa_portugal_3373008',
      'sao-miguel': 's%c3%a3o-miguel-island_portugal_3372598',
      'santa-maria': 'vila-do-porto_portugal_3372473'
    };
    const locationCode = locations[island]? locations[island] : 'azores_portugal_3373385';
    const meteoblueHost = `https://www.meteoblue.com/${langCode}/weather/widget/daily/`;
    const meteoblueQuery = "?geoloc=fixed&days=4&tempunit=CELSIUS&windunit=KILOMETER_PER_HOUR&precipunit=MILLIMETER&coloured=coloured&pictoicon=0&pictoicon=1&maxtemperature=0&maxtemperature=1&mintemperature=0&mintemperature=1&windspeed=0&windspeed=1&windgust=0&windgust=1&winddirection=0&winddirection=1&uv=0&humidity=0&precipitation=0&precipitation=1&precipitationprobability=0&precipitationprobability=1&spot=0&pressure=0&layout=light"
    const widget =
      <iframe
        className={css.iframe}
        src={`${meteoblueHost}${locationCode}${meteoblueQuery}`}
        frameBorder="0"
        scrolling="NO"
        sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
      ></iframe>;


    return widget;
  };

  const islandId = pageId[pageId.length-3] === '_' ? pageId.substr(0,pageId.length-3) : pageId;

  console.log("weather for island:", islandId);

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.iframeWrapper}>
        {weatherWidget(islandId)}
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionIframe.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionIframe.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionIframe;
